import MissionStatement from './MissionStatement';
import Contact from './Contact';

export const commonRoutes = [
   {
      component: MissionStatement,
      path: '/missionstatement'
   },
   {
      component: Contact,
      path: '/contact'
   }
];